jQuery(document).ready(function($){

    $('#investment_projects').hide();
    $('#id_investment_projects').prop('required', false );
    // Convert string mask to numbers
    $('#id_budget_sufficiency_cloned').on('change', function(){
        $('#id_budget_sufficiency').val($(this).val().replace(/,/g,""));
    });
    $('#id_budget_sufficiency_cloned').val($('#id_budget_sufficiency_cloned').val());

    var selectedValues = new Array();
    var s_v = new Array();
    $('#id_budget_lines').on('select2:unselect', function (e) {
        var data = e.params.data;
        $.each(selectedValues, function(index, val) {
            if (val !== undefined) {
                if (parseInt(data.id) === val.id) {
                    selectedValues.splice(index, 1);
                    s_v.splice(index, 1);
                }
                var cinco = s_v.includes(5);
                var seis = s_v.includes(6);
                if(cinco || seis){
                    $('#investment_projects').show();
                    $('#id_investment_projects').prop('required', true );
                }else{
                    $('#investment_projects').hide();
                    $('#id_investment_projects').prop('required', false );
                    $("#id_investment_projects").empty();
                }
            }
        });
    });
    $('#id_budget_lines').on('select2:select', function (e) {
        var data = e.params.data;
        data['_prep'] = parseInt(data.text.substr(0, 1));
        var _prep = parseInt(data.text.substr(0, 1));
        selectedValues.push(data);
        s_v.push(_prep);
        var cinco = s_v.includes(5);
        var seis = s_v.includes(6);
        if(cinco || seis){
            $('#investment_projects').show();
            $('#id_investment_projects').prop('required', true );
        }else{
            $('#investment_projects').hide();
            $('#id_investment_projects').prop('required', false );
            $("#id_investment_projects").empty();
        }
    });
});